import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon"

export default function Cta(props: any) {
    const {id, img, alt} = props

    return (
        <>
        <section className="cta-section w-full py-8 px-8 sm:pt-12 xl:max-w-7xl xl:mx-auto">
          <div className="mx-auto flex flex-col-reverse justify-start align-start sm:flex-row">
            <div className="px-4 pt-4 sm:pt-0">
              <img width="612" height="413" src={img} alt={alt} />
            </div>
            <div className="px-4 flex flex-col justify-between">
              <h2 className="text-secondary mb-4">Meet the PowerSchool Clouds</h2>
              <p className="text-offblack text-base sm:text-lg mb-4">Learn more about our Clouds - designed specifically to bring together all the digital assets you and your team need to be successful in your role.</p>
              <button className="flex flex-row justify-start text-tertiary text-lg">
                <a href="https://www.powerschool.com/powerschool-clouds/" target="_blank">Learn More</a>
                <ArrowRightIcon height="25"></ArrowRightIcon></button>
            </div>
          </div>

        </section>
        </>
    )
}
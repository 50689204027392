export default function CheckListItem(props: any) {
	const { id, title, className, icon } = props;

const DynamicComponent = (Component: any) => <Component />
	return (
		<>
			<li className={className}><span>{DynamicComponent(icon)}</span>{title}</li>
		</>
	)
}

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import CheckListItem from '../checklist/CheckListItem';

export function CarouselCard(props: any) {
    const { title, description, img, alt, link, checkList, tabIndex, isLastTab } = props;
    const handleClick = (event: string) => {
        props.onButtonClick(event);
    };

    const isFirstTab = tabIndex == 0;
    

    return (
        <>
            <div className="flex flex-col items-center justify-between md:flex-row md:justify-center h-full">
                <div className="info-box bg-white w-full p-6 m-2 flex flex-row justify-between md:w-3/6 relative info-box">
                    <div className="w-full">
                        <h3 className="text-secondary mb-5">{title}</h3>
                        <p className="mb-3">{description}</p>
                        {checkList.map((c: { id: number; title: string; className: string; img: string; alt: string; icon: any; }) => {
                            return (
                                <CheckListItem
                                    key={c.id}
                                    title={c.title}
                                    className={c.className}
                                    img={c.img}
                                    alt={c.alt}
                                    icon={c.icon}
                                ></CheckListItem>
                            )
                        })}
                        <div className="carousel-card-actions relative flex flex-row justify-between mt-3">
                            <button onClick={() => window.location.href = link} className="btn bg-tertiary text-white mt-6 text-lg hover:bg-pink-700">Learn More</button>
                            <div className="flex flex-row items-end">
                                <button className={isFirstTab ? 'disable-button rounded-full border-2 ' : 'rounded-full border-2 border-secondary transition transform '} onClick={() => { handleClick("previous");  }} disabled={isFirstTab}><ArrowLeftIcon></ArrowLeftIcon></button>
                                <button className={isLastTab ? 'rounded-full border-2 ml-4 disable-button': 'rounded-full border-2 border-secondary ml-4 transition transform'} onClick={() => { handleClick("next");  }} disabled={isLastTab}><ArrowRightIcon></ArrowRightIcon></button>
                            </div>
                        </div> 
                        
                    </div>
             
                </div>
                <div className="flex justify-center px-10 pt-4 w-full md:w-1/2">
                    <img src={img} alt={alt} />
                </div>
            </div>
        </>
    )
}
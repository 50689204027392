import { useState } from 'react';
import { CarouselCard } from './CarouselCard';
import { Tab } from '@headlessui/react';
import { classNames } from '~/utils/class-names';

export function TabbedCarousel(props: { slides: any[] }) {
  let tabs = props.slides;
  let [currentTab, setCurrentTab] = useState<any>('0');
  let isLastTab: boolean = currentTab == tabs.length - 1;

  const selectTab = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const handleButtonClick = (str: string) => {
    if (str == 'previous' && currentTab == '0') {
      return -1;
    }
    else if (str == 'next' && Number(currentTab) == tabs.length - 1) {
      return -1;
    }
    else if (str == 'next') {
      currentTab++;
      setCurrentTab(currentTab.toString());
    }
    else if (str == 'previous') {
      currentTab--;
      setCurrentTab(currentTab.toString());
    }
  }

  return (
    <>
        <div className="w-100 mx-auto">
          <div className="tab-section-head">
            <h2 className="text-secondary mb-4">See how our Clouds can help your team thrive</h2>
            <p>Select a role or team to see which PowerSchool Cloud can help you save time, reduce costs, improve student outcomes, and increase security with cloud-hosted solutions.</p>
          </div>
          <div className="tab-section-tabs py-4">

            <div className="w-full px-2 sm:px-0 min-h-lvh">
              <Tab.Group selectedIndex={currentTab} onChange={setCurrentTab}>
                <Tab.List className="flex flex-wrap -mb-px mt-4">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.id}
                      className={({ selected }) =>
                        classNames(
                          'focus:outline-none font-bold rounded-t-lg border-b-4 hover:text-blue-500 hover:border-blue-500 dark:hover:text-blue-500',
                          selected ? 'active font-bold text-pink-600 border-b-4 border-pink-600  dark:text-pink-500 dark:border-pink-500' : 'border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                        )
                      }
                    >
                      {tab.label}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                  {Object.values(tabs).map((t: any, i) => (
                    <Tab.Panel
                      key={i}
                      style={{ "minHeight": "500px" }}
                    >
                      <CarouselCard
                        key={i}
                        value={t.id}
                        title={t.title}
                        description={t.description}
                        img={t.img}
                        alt={t.alt}
                        link={t.link}
                        checkList={t.checkList}
                        onButtonClick={handleButtonClick}
                        tabIndex={currentTab}
                        isLastTab={isLastTab}
                      ></CarouselCard>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>

          </div>

        </div>

    </>
  );
}

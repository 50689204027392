import { Link, useLoaderData, useNavigate } from '@remix-run/react';
import { LoaderArgs } from '@remix-run/server-runtime';
import Cta from '~/components/cta/Cta';
import { AppList } from '~/components/marketplace/component-list/app-list';
import { TabbedCarousel } from '~/components/tabs/TabbedCarousel';
import Tile from '~/components/tile/Tile';
import { searchRecords } from '~/generated/bp-api';
import { AppInfo } from '~/generated/contract';
import { usePreviews } from '~/generated/tds/bp-utils';
import { cta, tabs, tiles } from '~/mock-data';


export async function loader({ request, params }: LoaderArgs) {
  const collection = new URL(request.url).searchParams.get('collection') ?? 'Featured';

  const tagsResponse: { r: Array<{ label: string, id: string, selected?: boolean}> } = { r: [{ label: 'Featured', id: 'featured' }] };

  const collections = tagsResponse.r?.map(c => c.label === collection ? { selected: true, ...c } : c);
  const appsResponse = await searchRecords('solution', '', [], [ { property: 'specs.tag', values: [ 'featured' ] } ], 10);
  if (appsResponse.records?.length) {
    appsResponse.records = usePreviews(appsResponse.records, '320x180');
  }
  return {
    apps: appsResponse?.records ? appsResponse.records.map(r => r.attributes) as AppInfo[] : [],
    collections
  };
}

export default function Index() {
  const { collections, apps } = useLoaderData<typeof loader>();


  return (
    <>

      <section className="">
        <div className="w-full flex flex-col justify-between circles sm:flex-row">
          <div className="flex flex-col w-full mr-4">
            <h2 className="text-secondary mb-4 sm:mb-8">PowerSchool Marketplace</h2>
            <h3 className="text-secondary mb-6 capitalize">Innovative products, add-on solutions, and professional services.</h3>
            <p className="text-offblack">Our Marketplace offers an extensive collection of products and services designed to elevate the efficiency of your school or district, powering operations and meeting your unique requirements with confidence. Explore a diverse range of solutions tailored to enhance every aspect of education management. From administrative tools to student engagement platforms, each product within Marketplace is thoughtfully curated to provide value. Embrace innovation, streamline processes, and empower your institution with the transformative capabilities found within our Marketplace.</p>
          </div>
          <div className="circles w-full relative">
            <img className="relative" src="/assets/img/collab.png" alt="" />
          </div>
        </div>
      </section>

      <section className="mt-8">
        <div className="pt-0 pb-0">
          <h2
            id="category-heading"
            className="text-secondary text-center"
          >
            Most Popular Solutions
          </h2>
        </div>
        <div>
          <div className="neon-1_2_0-grid-container">
            <div className="neon-1_2_0-grid-1-1 py-6">
              <div id="__mfe__tabs-example-react">
                {collections.map((tc, i) => (
                  <AppList key={i} apps={apps} />
                ))}
              </div>
            </div>
          </div>
          <div className="mt-6 lg:mt-10 flex justify-center">
            <Link to={`/collections/all`}>
              <button
                type="submit"
                className="w-full bg-primary border border-transparent py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-sky-500"
              >
                View All Solutions
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section className="w-full mx-auto mt-12 py-10 px-6 bg-gray-50">
        <div className=" max-w-6xl flex flex-col md:flex-row align-start justify-between">
          {tiles.map(t => {
            return (
              <Tile
                key={t.id}
                title={t.title}
                description={t.description}
                img={t.img}
                alt={t.alt}
                className={t.className}
                width={t.width}
              ></Tile>
            )
          })
          }
        </div>
      </section>


      {/* Call To Action */}
      {cta.map(c => {
        return (
          <Cta
            key={c.id}
            img={c.img}
            alt={c.alt}
          ></Cta>
        )
      })}


      <section className="tab-section relative py-8 px-8 ">

        {/* Tabbed Carousel  */}
        <TabbedCarousel slides={tabs} />
      </section>




    </>
  );
}


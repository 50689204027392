import { Link } from '@remix-run/react';
import { ReactElement } from 'react';
import { AppCard } from '~/components/products/AppCard';
import { AppInfo } from '~/generated/contract';




function getTagText(tagText: string): string {
  if (tagText.indexOf('NEON_VERSION_NEXT') !== -1) {
    return 'Coming Soon';
  } else if (tagText === '__NEON_VERSION__') {
    return 'New';
  } else {
    return tagText;
  }
}

function getTagType(tagText: string, tagType: string | undefined): string | undefined {
  if (tagText.indexOf('NEON_VERSION_NEXT') !== -1) {
    return 'navy';
  } else if (tagText === '__NEON_VERSION__') {
    return 'update';
  } else {
    return tagType;
  }
}

type AppListProps = {
  apps: AppInfo[];
};

export function AppList({ apps }: AppListProps): ReactElement {

  const sortedApps: Array<AppInfo> = apps.sort((c1: AppInfo, c2: AppInfo) => {
    if (c1.name > c2.name) {
      return 1;
    } else if (c1.name < c2.name) {
      return -1;
    } else {
      return 0;
    }
  });



  return sortedApps ? (<div>
    <div className="grid justify-center grid-cols-2 md:grid-cols-5 gap-y-4 gap-x-4 md:gap-y-8 md:gap-x-8 px-3 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:gap-x-8">
      {
        sortedApps.map((app: AppInfo, index: number) => <Link key={index}  to={`/apps/${app.slug}`}>
          <AppCard key={app.id} app={app} />
        </Link>
        )
      }
    </div>

  </div>) : <span>'Loading...'</span>;
}
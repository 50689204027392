export default function Tile(props: any) {
	const { id, title, description, img, alt, className, width } = props

	return (
		<div className={className}>
			<img src={img} alt={alt} width={width} />
			<h3 className="text-secondary my-2">{title}</h3>
			<p className="text-offblack text-base sm:text-lg">{description}</p>
		</div>
	)
}